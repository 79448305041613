<script>
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel';

export default {
  data() {
    return {
      autoCloseOnSelected: false,
      chatType: 'emoji',
      refreshNeeded: false,
      isSlickHandle: false,
      sliderOptions: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
        dots: true, // 인디케이터 활성화
        // variableWidth: true,
        // adaptiveHeight: true,
      },
      emojis: [
        {
          "type": "emoji",
          "name": "face-with-tears-of-joy",
          "content": "😂"
        },
        {
          "type": "emoji",
          "name": "pleading-face",
          "content": "🥺"
        },
        {
          "type": "emoji",
          "name": "smiling-face-with-heart-eyes",
          "content": "😍"
        },
        {
          "type": "emoji",
          "name": "face-blowing-a-kiss",
          "content": "😘"
        },
        {
          "type": "emoji",
          "name": "grinning-face-with-sweat",
          "content": "😅"
        },
        {
          "type": "emoji",
          "name": "smiling-face-with-halo",
          "content": "😇"
        },
        {
          "type": "emoji",
          "name": "fearful-face",
          "content": "😨"
        },
        {
          "type": "emoji",
          "name": "winking-face-with-tongue",
          "content": "😜"
        },
        {
          "type": "emoji",
          "name": "face-screaming-in-fear",
          "content": "😱"
        },
        {
          "type": "emoji",
          "name": "thinking-face",
          "content": "🤔"
        },
        {
          "type": "emoji",
          "name": "smirking-face",
          "content": "😏"
        },
        {
          "type": "emoji",
          "name": "face-with-rolling-eyes",
          "content": "🙄"
        },
        {
          "type": "emoji",
          "name": "unamused-face",
          "content": "😒"
        },
        {
          "type": "emoji",
          "name": "face-with-symbols-on-mouth",
          "content": "🤬"
        },
        {
          "type": "emoji",
          "name": "red-heart",
          "content": "❤️"
        },
        {
          "type": "emoji",
          "name": "drooling-face",
          "content": "🤤"
        },
        {
          "type": "emoji",
          "name": "zipper-mouth-face",
          "content": "🤐"
        },
        {
          "type": "emoji",
          "name": "weary-face",
          "content": "😩"
        },
        {
          "type": "emoji",
          "name": "angry-face",
          "content": "😠"
        },
        {
          "type": "emoji",
          "name": "pouting-face",
          "content": "😤"
        },
        {
          "type": "emoji",
          "name": "face-with-monocle",
          "content": "🧐"
        },
        {
          "type": "emoji",
          "name": "fire",
          "content": "🔥"
        },
        {
          "type": "emoji",
          "name": "smiling-face-with-sunglasses",
          "content": "😎"
        },
        {
          "type": "emoji",
          "name": "dizzy-face",
          "content": "😵"
        },
        {
          "type": "emoji",
          "name": "astonished-face",
          "content": "😲"
        },
        {
          "type": "emoji",
          "name": "smiling-face-with-horns",
          "content": "😈"
        },
        {
          "type": "emoji",
          "name": "partying-face",
          "content": "🥳"
        }
      ],
      comments: [
        {
          "type": "message",
          "name": "hello",
          "content": "안녕하세요"
        },
        {
          "type": "message",
          "name": "nice-to-meet-you",
          "content": "반가워요!"
        },
        {
          "type": "message",
          "name": "thank-you",
          "content": "감사합니다!"
        },
        {
          "type": "message",
          "name": "sorry",
          "content": "죄송합니다~"
        },
        {
          "type": "message",
          "name": "nice-call",
          "content": "나이스 콜!"
        },
        {
          "type": "message",
          "name": "nice-hand",
          "content": "나이스 핸드!"
        },
        {
          "type": "message",
          "name": "nice-bet",
          "content": "나이스 벳!"
        },
        {
          "type": "message",
          "name": "nice-fold",
          "content": "나이스 폴드!"
        },
        {
          "type": "message",
          "name": "hurry-up",
          "content": "빨리 빨리!"
        },

        {
          "type": "message",
          "name": "i-won",
          "content": "내가 이겼어요!"
        },
        {
          "type": "message",
          "name": "all-in-next-round",
          "content": "다음판에 올인!"
        },
        {
          "type": "message",
          "name": "all-in-now-round",
          "content": "올인 간드아!"
        },
        {
          "type": "message",
          "name": "bluffing",
          "content": "블러핑 아님?"
        },
        {
          "type": "message",
          "name": "what-are-you-doing",
          "content": "너 뭐 돼?"
        },
        {
          "type": "message",
          "name": "come-here",
          "content": "드루와 드루와~"
        },
        {
          "type": "message",
          "name": "go-ahead",
          "content": "진행시켜"
        },
        {
          "type": "message",
          "name": "return-it",
          "content": "돌려줘요"
        },
        {
          "type": "message",
          "name": "good",
          "content": "좋았쒀!"
        },
        {
          "type": "message",
          "name": "crazy-form",
          "content": "폼 미쳤따!"
        },
        {
          "type": "message",
          "name": "oops",
          "content": "이게 안 뜨네..."
        },
        {
          "type": "message",
          "name": "bring-it-all",
          "content": "싹~다 가져와!"
        },
      ],
    }
  },
  watch: {
    chatType: {
      immediate: true,
      handler: 'refreshSlick'
    },
    // chatType() {
    //   if (this.refreshNeeded) {
    //     return;
    //   }
    //
    //   $('.wrap_bubble').slick('refresh');
    //
    //   this.refreshNeeded = true;
    // },
    autoCloseOnSelected() {
      if (!this.autoCloseOnSelected) {
        return;
      }

      this.autoCloseOnSelected = this.autoCloseOnSelected && this.$refs.close.click();
    }
  },
  methods: {
    refreshSlick(current, prev) {
      $(this.$refs[prev]).slick('unslick');

      this.$nextTick(() => {
        $(this.$refs[this.chatType]).slick(this.sliderOptions);
        $(this.$refs[this.chatType]).off('beforeChange').on('beforeChange', () => {
          this.isSlickHandle = true;
        });
        $(this.$refs[this.chatType]).off('afterChange').on('afterChange', () => {
          this.isSlickHandle = false;
        });
        setTimeout(() => {
          $(this.$refs[this.chatType]).slick('refresh');
        }, 1)
      });
    },
    changeChatMenu(type) {
      this.chatType = type;
    },
    onSelectEmotion(emotion) {
      if( this.isSlickHandle ){
        return;
      }
      this.$emit('emotion', emotion);
      this.autoCloseOnSelected = true;
    }
  }
}
</script>

<template>
  <!-- 이모티콘/말풍선 -->
  <div class="layerpopup_emoji offcanvas offcanvas-bottom" data-bs-scroll="true" data-bs-backdrop="false" tabindex="-1" id="layerpopup_emoji">
    <div class="emoji_header">
      <div class="wrap_tab">
        <div class="tab_item" :class="{ active: chatType === 'emoji' }">
          <button type="button" @click.prevent.stop="changeChatMenu('emoji')">이모티콘</button>
        </div>
        <div class="tab_item" :class="{ active: chatType === 'message' }">
          <button type="button" @click.prevent.stop="changeChatMenu('message')">말풍선</button>
        </div>
      </div>
      <button ref="close" type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div class="emoji_content">
      <!--이모티콘 페이지-->
      <div v-if="chatType === 'emoji'" ref="emoji" class="wrap_emoji" :class="chatType">
        <div class="page_emoji">
          <div
            v-for="(emoji, index) in emojis.slice(0, 14)"
            :key="index"
            class="emoji_item"
            @click.prevent.stop="onSelectEmotion(emoji)"
          >
            {{ emoji.content }}
          </div>
        </div>

        <div class="page_emoji">
          <div
            v-for="(emoji, index) in emojis.slice(14)"
            :key="index"
            class="emoji_item"
            @click.prevent.stop="onSelectEmotion(emoji)"
          >
            {{ emoji.content }}
          </div>
        </div>
      </div>

      <!--말풍선 페이지-->
      <div v-if="chatType === 'message'" ref="message" class="wrap_bubble">
        <div class="page_bubble">
          <div
            v-for="(comment, index) in comments.slice(0, 6)"
            :key="index"
            class="bubble_item"
            @click.prevent.stop="onSelectEmotion(comment)"
          >
            <div class="speech-bubble">{{ comment.content }}</div>
          </div>
        </div>
        <div class="page_bubble">
          <div
            v-for="(comment, index) in comments.slice(6, 12)"
            :key="index"
            class="bubble_item"
            @click.prevent.stop="onSelectEmotion(comment)"
          >
            <div class="speech-bubble">{{ comment.content }}</div>
          </div>
        </div>
        <div class="page_bubble">
          <div
              v-for="(comment, index) in comments.slice(12, 18)"
              :key="index"
              class="bubble_item"
              @click.prevent.stop="onSelectEmotion(comment)"
          >
            <div class="speech-bubble">{{ comment.content }}</div>
          </div>
        </div>
        <div class="page_bubble">
          <div
              v-for="(comment, index) in comments.slice(18, 24)"
              :key="index"
              class="bubble_item"
              @click.prevent.stop="onSelectEmotion(comment)"
          >
            <div class="speech-bubble">{{ comment.content }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.emoji_content .slick-list {
  max-height: 4.5rem;
  overflow: hidden;
}

.emoji_content .emoji_item, .emoji_content .speech-bubble {
  cursor: pointer;
}
</style>